import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-fetch';
const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: createUploadLink({
		uri: process.env.GATSBY_SERVER_URL,
		fetch,
		fetchOptions: {
			// This ensures our cookies get sent with Apollo requests
			credentials: 'include',
		},
	}) as any, // TODO: Fix clash in Typescript types between 'apollo-upload-client' and '@apollo/client'
});

export const ApolloManager = {
	client,
};
