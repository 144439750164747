// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contactUs/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-layout-index-tsx": () => import("./../../../src/pages/layout/index.tsx" /* webpackChunkName: "component---src-pages-layout-index-tsx" */),
  "component---src-pages-membership-index-tsx": () => import("./../../../src/pages/membership/index.tsx" /* webpackChunkName: "component---src-pages-membership-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-templates-about-about-page-tsx": () => import("./../../../src/templates/about/aboutPage.tsx" /* webpackChunkName: "component---src-templates-about-about-page-tsx" */),
  "component---src-templates-article-article-details-template-tsx": () => import("./../../../src/templates/article/articleDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-article-article-details-template-tsx" */),
  "component---src-templates-article-protected-article-details-template-tsx": () => import("./../../../src/templates/article/protectedArticleDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-article-protected-article-details-template-tsx" */),
  "component---src-templates-home-home-page-tsx": () => import("./../../../src/templates/home/homePage.tsx" /* webpackChunkName: "component---src-templates-home-home-page-tsx" */),
  "component---src-templates-industry-industry-page-tsx": () => import("./../../../src/templates/industry/industryPage.tsx" /* webpackChunkName: "component---src-templates-industry-industry-page-tsx" */),
  "component---src-templates-media-media-page-tsx": () => import("./../../../src/templates/media/mediaPage.tsx" /* webpackChunkName: "component---src-templates-media-media-page-tsx" */),
  "component---src-templates-programs-and-services-programs-and-services-page-tsx": () => import("./../../../src/templates/programsAndServices/programsAndServicesPage.tsx" /* webpackChunkName: "component---src-templates-programs-and-services-programs-and-services-page-tsx" */),
  "component---src-templates-project-project-details-template-tsx": () => import("./../../../src/templates/project/projectDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-project-project-details-template-tsx" */),
  "component---src-templates-protected-page-tsx": () => import("./../../../src/templates/protected-page.tsx" /* webpackChunkName: "component---src-templates-protected-page-tsx" */),
  "component---src-templates-public-page-tsx": () => import("./../../../src/templates/public-page.tsx" /* webpackChunkName: "component---src-templates-public-page-tsx" */),
  "component---src-templates-publication-protected-publication-details-template-tsx": () => import("./../../../src/templates/publication/protectedPublicationDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-publication-protected-publication-details-template-tsx" */),
  "component---src-templates-publication-publication-details-template-tsx": () => import("./../../../src/templates/publication/publicationDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-publication-publication-details-template-tsx" */),
  "component---src-templates-report-protected-report-details-template-tsx": () => import("./../../../src/templates/report/protectedReportDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-report-protected-report-details-template-tsx" */),
  "component---src-templates-report-report-details-template-tsx": () => import("./../../../src/templates/report/reportDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-report-report-details-template-tsx" */),
  "component---src-templates-research-and-development-research-and-development-page-tsx": () => import("./../../../src/templates/researchAndDevelopment/researchAndDevelopmentPage.tsx" /* webpackChunkName: "component---src-templates-research-and-development-research-and-development-page-tsx" */),
  "component---src-templates-search-index-tsx": () => import("./../../../src/templates/search/index.tsx" /* webpackChunkName: "component---src-templates-search-index-tsx" */),
  "component---src-templates-video-protected-video-details-template-tsx": () => import("./../../../src/templates/video/protectedVideoDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-video-protected-video-details-template-tsx" */),
  "component---src-templates-video-video-details-template-tsx": () => import("./../../../src/templates/video/videoDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-video-video-details-template-tsx" */),
  "component---src-templates-welfare-welfare-page-tsx": () => import("./../../../src/templates/welfare/welfarePage.tsx" /* webpackChunkName: "component---src-templates-welfare-welfare-page-tsx" */)
}

