import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloManager } from 'src/utils/apollo-manager';

// Global Styles
import './src/styles/reset.css';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={ApolloManager.client}>{element}</ApolloProvider>
);

export const onInitialClientRender = () => {
	const hash = window.location.hash?.replace('#', '');
	if (hash) {
		// trigger at the end of the rendering event
		setTimeout(() => {
			const docElement = document.getElementById(hash);
			docElement?.scrollIntoView({ behavior: 'smooth' });
		});
	}
};
